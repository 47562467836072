<template>
	<Modal v-model="showModal" :modalClass="this.modalClass">
		<template v-if="this.type == 'video'">
			<iframe v-lazy-load :data-src="this.iframeURL" title="B+N Video" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" ref="iframe"></iframe>
		</template>
        
        <template v-if="this.type == 'contact'">
            <FormContact/>
        </template>

		<template v-else>
			<UserLogin />
		</template>
	</Modal>
</template>

<style lang="scss"></style>

<script>
export default {
	components: {
		Modal: () => import('@/components/Modal.vue'),
		UserLogin: () => import('@/components/UserLogin.vue'),
        FormContact: () => import('@/components/FormContact.vue')
	},
	props: [],
	data() {
		return {
			iframeURL: null,
			modalClass: '',
			showModal: false,
			type: null
		}
	},
	mounted() {
		this.$bus.$on('openModal', () => {
			// Show Modal
			this.showModal = true
			this.modalClass = ''
			this.type = ''
		})

		this.$bus.$on('openVideoModal', (params) => {
			if (params && params.type && params.url) {
				// Video Modal Config
				this.type = params.type
				this.modalClass = 'video-modal'
				this.iframeURL = params.url
				const urlObject = new URL(params.url)

				// If Vimeo
				if (urlObject.hostname.match('vimeo'))
					this.iframeURL += '?color=ff5500&autoplay=1&autopause=0&title=0&byline=0&playsinline=0&portrait=0'

				// If YouTube
				else if (urlObject.hostname.match('youtube'))
					this.iframeURL += '?autoplay=1&rel=0&autohide=1&showinfo=0'

				// Show Modal
				this.showModal = true
			}
		})

        this.$bus.$on('openContactModal', () => {
			this.showModal = true;
			this.modalClass = '';
			this.type = 'contact';
		})
	}
}
</script>
