var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { modalClass: this.modalClass },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      this.type == "video"
        ? [
            _c("iframe", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              ref: "iframe",
              attrs: {
                "data-src": this.iframeURL,
                title: "B+N Video",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: "true"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      this.type == "contact" ? [_c("FormContact")] : [_c("UserLogin")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }